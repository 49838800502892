<template>
    <div class="max-w-sm mx-auto mt-257 px-12 md:px-0">
        <!-- Title -->
        <Title
            :title="$t('passwordConfirmationFormTitle')"
            :subtitle="$t('passwordConfirmationFormSubtitle')"
        />

        <!-- General error message -->
        <p
            v-if="error"
            class="text-red-500 small my-10"
        >
            {{error}}
        </p>

        <!-- Password confirmation form -->
        <form
            @submit.prevent="onSubmit"
            ref="setPasswordForm"
        >
            <!-- Password input -->
            <Input
                class="mb-22"
                :error="errors.password"
                :label="$t('passwordFieldLabel')"
                :placeholder="$t('passwordFieldPlaceholder')"
                type="password"
                name="password"
            />

            <!-- Password confirmation -->
            <Input
                class="mb-37"
                :error="errors.password_confirmation"
                :label="$t('passwordConfirmationFieldLabel')"
                :placeholder="$t('passwordConfiramtionFieldPlaceholder')"
                type="password"
                name="password_confirmation"
            />

            <!-- Submit btn -->
            <Button
                class="button bg-purple-500 text-white hover:bg-purple-600 mb-5 font-bold"
                role="submit"
                :text="$t('passwordConfrimationButtonText')"
                :disabled="registerSetPasswordLoading"
                :spinning="registerSetPasswordLoading"
            />
        </form>
    </div>
</template>

<script>
import Input from '@/components/form/Input'
import Button from '@/components/buttons/Button'
import Title from '@/components/texts/FormTitle'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'SignupPassword',

    components: {
        Input,
        Button,
        Title
    },

    data () {
        return {
            registerSetPasswordLoading: false
        }
    },

    computed: {
        ...mapGetters(['activationCode', 'smsCode', 'token', 'userType', 'errors', 'error'])
    },

    methods: {
        /** Vuex actions. */
        ...mapActions(['signupSetPassword']),

        /** Set password form. */
        onSubmit () {
            this.registerSetPasswordLoading = true

            this.signupSetPassword({
                activation_code: this.activationCode,
                sms_code: this.smsCode,
                password: this.$refs.setPasswordForm.password.value,
                password_confirmation: this.$refs.setPasswordForm.password_confirmation.value
            })
                .then(() => {
                    this.registerSetPasswordLoading = false
                })
        }
    }
}
</script>
